import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";

import { getWarrantyInboxCount } from "../../../../store/aftersales/aftersalesSaga";
import {
  selectActiveDoor,
  selectAtLeastOneTruePrivileges,
  selectCanBuySparepartsGlobal,
  selectIsSubuser,
  selectMultiDoors,
  selectSelectedDoor,
} from "../../../../store/user/userSlice";
import { NavbarSectionLink } from "../../../pages/my-account/account-navbar/navbarSections";
import CustomText from "../../../styled-UI/CustomText";
import ConditionalRender from "../../../widgets/ConditionalRender";
import useNavbarSectionLinkHandler from "../navbar/useNavbarSectionLinkHandler";

import { ReactComponent as Arrow } from "../../../../assets/icons/arrowhead-down-icon.svg";
import {
  selectSectionAlerts,
  selectSectionsAlertsStatus,
} from "../../../../store/accounting/accountingSlice";
import { externalReferenceNewTag } from "../../../../utils/cmsUtils";
import BoxCounter from "../../../styled-UI/BoxCounter";
import Loader from "../../../styled-UI/Loader";
import NewTag from "../../../widgets/tutorial-pills/new-tag/NewTag";

interface Props {
  title: string;
  contents: NavbarSectionLink[];
  isOpen: boolean;
  onToggle: () => void;
  showAccountMenu: (showMenu: boolean) => void;
}

const AccountMenuAccordion = ({
  title,
  contents,
  isOpen,
  onToggle,
  showAccountMenu,
}: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();
  const dispatch = useDispatch();

  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();

  const userPrivileges = useSelector(selectAtLeastOneTruePrivileges);
  const selectedDoor = useSelector(selectSelectedDoor);

  //const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrollingElement, setScrollingElement] = useState<string>("");
  const [showColumn, setShowColumn] = useState<boolean>(false);
  const canBuySparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  const multidoors = useSelector(selectMultiDoors);
  const sectionAlerts = useSelector(selectSectionAlerts);
  const sectionsAlertsStatus = useSelector(selectSectionsAlertsStatus);

  const privileges = canRender("AFTER_SALES_REQUEST") || canRender("SERVICE_REQUEST_PAGE");

  //check if there are subitems with count to show in order to print white dot in blue accordion :)
  const isIdsInCounts = useCallback((): boolean => {
    return (
      !!sectionAlerts &&
      contents.some(
        (item) => !item?.notShowForSubuser && item?.serviceId?.some((id) => sectionAlerts[id] > 0)
      )
    );
  }, [sectionAlerts, contents]);

  useEffect(() => {
    if (selectedDoor)
      if (isOpen && title === "AFTERSALES_SECTION" && privileges) {
        dispatch(getWarrantyInboxCount(selectedDoor?.orgentityId));
      }
  }, [isOpen]);

  useEffect(() => {
    if (userPrivileges) {
      let status = false;

      contents.forEach((item) => {
        if (item.privilege) {
          if (isSubuser && item.exclusivePrivilege) {
            if (mainDoor && multidoors.includes(mainDoor) && canRender(item.privilege, mainDoor)) {
              status = true;
            } else if (canRender(item.privilege)) status = true;
          } else if (canRender(item.privilege)) {
            status = true;
          }
        } else if (!item.privilege) status = true;
      });

      if (status !== showColumn) setShowColumn(status);
    }
  }, [userPrivileges]);

  const handleClick = (link: NavbarSectionLink) => handleNavbarSectionLinkClick(link);

  const isAccordionItemVisibile = (item: NavbarSectionLink): boolean => {
    if (isSubuser && item.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        !canRender(item.privilege, mainDoor)
      )
        return false;
    }
    if (!canBuySparepartsGlobal && item.title === "WARRANTY_TITLE") {
      return false;
    }
    if (isSubuser && item.notShowForSubuser) return false;
    return true;
  };

  useEffect(() => {
    if (isOpen && scrollingElement) {
      const menuCategory = document.getElementById(scrollingElement);
      if (menuCategory) {
        menuCategory.scrollIntoView({ behavior: "smooth" });
        menuCategory.scrollTop -= 10;
      }
    }
  }, [isOpen, scrollingElement]);

  if (showColumn) {
    return (
      <Accordion
        className={clsx(isOpen && "accordion-open")}
        id={`MainNav_MyAccount_Overlay_Links_${title}`}
      >
        <AccordionHeader
          onClick={() => {
            //setIsOpen(!isOpen);
            onToggle();
            setScrollingElement(`MainNav_MyAccount_Overlay_Links_${title}`);
          }}
          data-element-id={`MainNav_MyAccount_Overlay_Links_${title}`}
        >
          <HeaderListContainer>
            <HeaderText>
              <CustomText as="span" fontSizePx={13} font="font-medium" lineHeightPx={18}>
                {translateLabel(title)}
              </CustomText>
              {isIdsInCounts() ? (
                <HeaderNotification />
              ) : (
                sectionsAlertsStatus === "LOADING" &&
                !sectionAlerts && (
                  <LoaderContainer>
                    <Loader sizePx={10} />
                  </LoaderContainer>
                )
              )}
            </HeaderText>
            <NewTag position={externalReferenceNewTag[`Account.${title}`]}></NewTag>
          </HeaderListContainer>

          <Arrow className={clsx(isOpen && "arrow-up")} />
        </AccordionHeader>
        {isOpen &&
          contents.map((item) => {
            return (
              <ConditionalRender
                key={item.url}
                privilege={item.privilege}
                onlySubuser={item.onlySubuser}
                isNotBOUser={item.isNotBOUser}
              >
                {isAccordionItemVisibile(item) && (
                  <AccordionItem>
                    <button
                      onClick={() => {
                        showAccountMenu(false);
                        handleClick(item);
                      }}
                      data-element-id={`MainNav_MyAccount_Overlay_Links_${title}_${item.dataElementId}`}
                      style={{ width: "100%" }}
                    >
                      <WrapCount>
                        <CustomText as="span" fontSizePx={13} font="font-medium" lineHeightPx={18}>
                          {translateLabel(item.title)}
                        </CustomText>
                        {item.serviceId && <BoxCounter ids={item.serviceId} />}
                      </WrapCount>
                    </button>
                  </AccordionItem>
                )}
              </ConditionalRender>
            );
          })}
      </Accordion>
    );
  }
  return null;
};

const Accordion = styled.div`
  border: 1px solid ${(props) => props.theme.palette.primary};
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.125rem 1.125rem 1.125rem 1.5rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.primary};

  span {
    color: ${(props) => props.theme.palette.gray.light};
  }

  div {
    margin: unset;
    padding: unset;
  }

  svg {
    height: 1rem;
    fill: ${(props) => props.theme.palette.white};

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
`;

const HeaderListContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  div {
    margin: unset;
    padding: unset;
  }
`;

const HeaderText = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
`;

const LoaderContainer = styled.div`
  height: 0.5rem;
  width: 0.5rem;
`;

const HeaderNotification = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
`;

const AccordionItem = styled.div`
  padding: 1.125rem 1.125rem 1.125rem 1.5rem;
  border-top: solid 1px ${(props) => props.theme.palette.gray.medium};
`;

export const WrapCount = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const BoxCount = styled.div`
  border-radius: 1.125rem;
  background-color: ${(props) => props.theme.palette.primary};
  width: 1.875rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
`;

const BoxLoader = styled.div`
  margin-right: 10px;
`;

export default AccountMenuAccordion;
